.FACEBOOK {
  width: 100%;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  font-size: 0.9vw !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #0073b1 !important;
  padding: 10px;
  margin-bottom: 10px;
  height: 6vh;
  margin-top: 10px;
}
.GOOGLE {
  width: 100%;
  text-align: center !important;
  align-items: center;
}
.NHSBUTTON {
  width: 100%;
  background-color: white;
}
