.progress {
    position: relative;
    height: 10px;
    width: 100%;
    background-color: #DCDCDC;
    border-radius: 10px;
  }
  
  .progress-bar {
    height: 100%;
    border-radius: 10px;
    position: relative;
  }
  @media only screen and (min-width: 700px) {
    .progress-label {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 30px;
      height: 30px;
   
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 16px;
      font-weight: bold;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
      font-family: sans-serif;
  }
}
@media screen and (max-width:600px) {
  .progress-label {
    position: absolute;
    top: -6px;
    right: -4px;
    width: 20px;
    height: 20px;
 
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    font-family: sans-serif;
}
}